import type { BrandPageResponse, BrandsListResponse } from '~/types/Brand';
import type { CatalogResponse, OfferFull, OfferMedium, SaleResponse } from '~/types/Catalog';
import type { CategoriesListResponse } from '~/types/Category';
import type { SearchPageResponse } from '~/types/Search';
import type { FavoriteItemsListResponse } from '~/types/Favorite';

export interface CatalogStore {
  currentPageType: string;
  catalogPageIsLoading: boolean;

  productPageFoldableBlocks: {
    description: boolean;
    characteristics: boolean;
    videos: boolean;
    reviews: boolean;
  };

  selectedOffer: OfferFull;
  selectedSizeId: number | null;

  favoriteOffersIds: number[];
}

export const useCatalogStore = createCatalogStore({
  currentPageType: '',
  catalogPageIsLoading: false,

  productPageFoldableBlocks: {
    description: true,
    characteristics: true,
    videos: true,
    reviews: true,
  },

  selectedOffer: null as unknown as OfferFull,
  selectedSizeId: null,

  favoriteOffersIds: [] as number[],
});

export function createCatalogStore(initialState: CatalogStore) {
  return defineStore('catalog', {
    state: () => initialState,

    actions: {
      async getCategories() {
        try {
          const { data } = await useCustomFetch<CategoriesListResponse>('/catalog/categories', {}, '2');

          if (data.value) {
            return data.value.data.categories;
          } else {
            return [];
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return [];
        }
      },

      async getBrands() {
        try {
          const { data } = await useCustomFetch<BrandsListResponse>('/catalog/brands', {}, '2');

          if (data.value) {
            return data.value.data.brands;
          } else {
            return [];
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return [];
        }
      },

      async getBrandBySlug() {
        try {
          this.catalogPageIsLoading = true;
          const { query, params } = useRoute();

          const { data } = await useCustomFetch<BrandPageResponse>(`/catalog/brands/${params.name}`, { query }, '2');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        } finally {
          this.catalogPageIsLoading = false;
        }
      },

      async getSale() {
        try {
          this.catalogPageIsLoading = true;
          const { query } = useRoute();

          const { data } = await useCustomFetch<SaleResponse>('/catalog/sales', { query }, '2');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        } finally {
          this.catalogPageIsLoading = false;
        }
      },

      async getFavorites() {
        try {
          this.catalogPageIsLoading = true;
          const { query } = useRoute();

          const { data } = await useCustomFetch<FavoriteItemsListResponse>('/favorite', { query }, '2');

          if (data.value) {
            this.favoriteOffersIds = data.value.data.offers.map((offer: OfferMedium) => offer.id);

            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        } finally {
          this.catalogPageIsLoading = false;
        }
      },

      async toggleFavorite(offerId: number) {
        const data = await useCustomFetchClient<{ data: []; success: boolean }>('/favorite', {
          method: 'POST',
          body: { id: offerId, model: 'offer' },
        });

        if (data?.success) {
          if (this.favoriteOffersIds.length && this.favoriteOffersIds.includes(offerId)) {
            this.favoriteOffersIds = this.favoriteOffersIds.filter((id: number) => id !== offerId);
          } else {
            this.favoriteOffersIds.push(offerId);
          }
        }
      },

      async getSearchResults() {
        try {
          this.catalogPageIsLoading = true;
          const { query } = useRoute();

          const roistatVisitId = useCookie('roistat_visit');
          if (roistatVisitId.value) query.roistat = roistatVisitId.value;

          const { data } = await useCustomFetch<SearchPageResponse>('/search', { query }, '2');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        } finally {
          this.catalogPageIsLoading = false;
        }
      },

      async getCatalogData(page?: string) {
        try {
          this.catalogPageIsLoading = true;
          const { query, params } = useRoute();
          let slug = params.slug;

          if (slug && Array.isArray(slug)) {
            slug = slug.join('/');
          } else if (!slug) {
            return null;
          }

          if (page) query.page = page;

          const { data } = await useCustomFetch<CatalogResponse>(`/catalog/show/${slug}`, { query }, '2');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        } finally {
          this.catalogPageIsLoading = false;
        }
      },

      async getOffersList(ids: string) {
        try {
          const { data } = await useCustomFetch<OfferMedium[]>(`/offers`, { query: { ids } }, '2');

          if (data.value) {
            return data.value;
          } else {
            return [];
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return [];
        }
      },
    },
  });
}
