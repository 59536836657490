<script lang="ts" setup>
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import type { Ref } from 'vue';
import type { StaticPagesListItem } from '~/types/StaticPageList';
import type { Swiper as SwiperType } from 'swiper/types';

const props = defineProps<{
  items: StaticPagesListItem[];
  title?: string | null;
  link?: string | null;
  isShowAnnotation?: boolean;
  isObserve?: boolean;
  blockName?: string;
}>();
const emits = defineEmits(['load-more']);

const isShowSlider = computed(() => !!props.items?.length);
const staticPageCardsGallerySwiper: Ref<SwiperType | null> = ref(null);
const staticPageSwiperRef = ref();

function sliderInitialization() {
  staticPageCardsGallerySwiper.value = new Swiper(staticPageSwiperRef.value, {
    breakpoints: {
      640: {
        centeredSlides: false,
        slidesOffsetBefore: 12,
      },
      768: {
        centeredSlides: false,
        slidesOffsetBefore: 12,
        spaceBetween: 24,
      },
      1024: {
        centeredSlides: false,
        slidesOffsetBefore: 0,
        spaceBetween: 24,
      },
    },
    centeredSlides: true,
    loop: true,
    loopAddBlankSlides: true,
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.static-page-cards-gallery__next-' + props.title?.replaceAll(' ', ''),
      prevEl: '.static-page-cards-gallery__prev-' + props.title?.replaceAll(' ', ''),
    },
    slidesPerView: 'auto',
    spaceBetween: 12,
    speed: 500,
  });

  staticPageCardsGallerySwiper.value.on('transitionEnd', () => {
    if (
      staticPageCardsGallerySwiper.value?.realIndex &&
      staticPageCardsGallerySwiper.value.realIndex >= props.items.length - 4
    ) {
      emits('load-more');
    }
  });
}

const { sendBannerEventToDataLayer } = useGtmModule();

onMounted(() => {
  sliderInitialization();

  if (props.isObserve) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sendEvent = () => {
              if (staticPageCardsGallerySwiper.value?.realIndex !== undefined) {
                sendBannerEventToDataLayer('view_promotion', {
                  promotion_id: props.items[staticPageCardsGallerySwiper.value.realIndex].id,
                  promotion_name: props.items[staticPageCardsGallerySwiper.value.realIndex].name,
                  creative_slot: staticPageCardsGallerySwiper.value.realIndex,
                  location_id: props.blockName,
                });
              }
            };

            sendEvent();
            staticPageCardsGallerySwiper.value?.on('slideChangeTransitionEnd', sendEvent);
          } else {
            staticPageCardsGallerySwiper.value?.off('slideChange');
          }
        });
      },
      { threshold: 1.0 },
    );

    observer.observe(staticPageSwiperRef.value);
  }
});

function sendSelectPromotionEvent(item: StaticPagesListItem) {
  sendBannerEventToDataLayer('select_promotion', {
    promotion_id: item.id,
    promotion_name: item.name,
    creative_slot: staticPageCardsGallerySwiper.value?.realIndex,
    location_id: props.blockName,
  });
}
</script>

<template>
  <section
    v-if="isShowSlider"
    class="static-page-cards-gallery"
  >
    <div
      v-if="title"
      class="px-3 lg:px-0"
    >
      <AppLink
        v-if="link"
        :to="link"
        class="w-fit"
      >
        <h2 class="inline underline underline-offset-4">
          {{ title }}
        </h2>
      </AppLink>

      <h2
        v-else
        class="inline"
      >
        {{ title }}
      </h2>
    </div>

    <div class="relative">
      <div
        ref="staticPageSwiperRef"
        class="swiper static-page-cards-gallery__container"
      >
        <div class="swiper-wrapper">
          <AppLink
            v-for="item in items"
            :key="item.id"
            :to="makeUrlRelative(item.url)"
            class="swiper-slide static-page-cards-gallery__item"
            @click="sendSelectPromotionEvent(item)"
          >
            <StaticPageListItem
              :is-show-annotation="isShowAnnotation"
              :item
            />
          </AppLink>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.static-page-cards-gallery {
  @include screen-lg {
    @include inner-content;
  }

  &__container {
    width: 100%;
    padding: 16px 0;
  }

  &__item {
    width: 80%;
    height: auto;

    @include screen-sm {
      width: 368px;
    }
  }
}
</style>
