<script lang="ts" setup>
import { CATALOG_PAGE_TYPES } from '~/constants/catalogPageTypes';
import type { FooterMenuItem } from '~/types/SiteSettings';

const footerMenu = ref<FooterMenuItem[]>([]);
const legalLinks = ref<FooterMenuItem[]>([]);

const route = useRoute();
const siteStore = useSiteStore();
const footerMenuResponse = await siteStore.getFooterMenu();

footerMenu.value = footerMenuResponse?.filter((item: FooterMenuItem) => item.name !== 'other');

const otherFooterBlock = footerMenuResponse?.find((item: FooterMenuItem) => item.name === 'other');
if (otherFooterBlock) legalLinks.value = otherFooterBlock.children;

const { currentPageType } = storeToRefs(useCatalogStore());
</script>

<template>
  <footer class="footer__wrapper">
    <div
      :class="
        [CATALOG_PAGE_TYPES.OFFER_PAGE, CATALOG_PAGE_TYPES.OFFER_GROUP_PAGE].includes(currentPageType) &&
        route.name === 'catalog-slug'
          ? 'pb-[158px]'
          : 'pb-[74px]'
      "
      class="footer__content"
    >
      <section class="footer__info">
        <div class="footer__info-links">
          <template
            v-for="column in footerMenu"
            :key="column.id"
          >
            <div
              v-if="column.enabled"
              class="flex flex-col items-start text-start gap-2 lg:gap-4"
            >
              <div class="flex gap-1 items-center font-medium leading-5 mb-1">
                <div class="flex gap-1 items-center font-medium leading-5 mb-1">
                  {{ column.name }}

                  <img
                    v-if="column.image_url"
                    :alt="column.name"
                    :src="column.image_url"
                    class="size-5"
                  />
                  <div
                    v-else-if="column.data?.icon"
                    class="size-5"
                    v-html="column.data.icon"
                  />
                </div>
              </div>

              <template
                v-for="item in column.children"
                :key="item.name"
              >
                <AppLink
                  v-if="item.enabled"
                  :target="item.target"
                  :to="makeUrlRelative(item.value)"
                >
                  <div class="flex gap-1 items-center">
                    {{ item.name }}

                    <img
                      v-if="item.image_url"
                      :alt="item.name"
                      :src="item.image_url"
                      class="size-4"
                    />
                    <div
                      v-else-if="item.data?.icon"
                      class="size-4"
                      v-html="item.data.icon"
                    />
                  </div>
                </AppLink>
              </template>
            </div>
          </template>
        </div>

        <div class="flex flex-col items-center gap-6">
          <ContactsBlock class="footer__contacts" />

          <div class="footer__social">
            <a
              :href="siteStore.socialNetworkLinks.vkUrl ?? undefined"
              target="_blank"
            >
              <CardBlock class="grid place-content-center size-16">
                <SvgoSocialVk
                  :font-controlled="false"
                  class="h-8 fill-hell-black"
                  filled
                />
              </CardBlock>
            </a>

            <a
              :href="siteStore.socialNetworkLinks.youtubeUrl ?? undefined"
              target="_blank"
            >
              <CardBlock class="grid place-content-center size-16">
                <SvgoSocialYoutube
                  :font-controlled="false"
                  class="h-8 fill-hell-black"
                  filled
                />
              </CardBlock>
            </a>
          </div>

          <YandexRatingWidget />

          <div class="footer__payments">
            <div class="text-xs text-hell-black-60">Мы принимаем к оплате:</div>

            <div class="flex justify-center gap-4">
              <SvgoPaymentSystemVisa
                :font-controlled="false"
                class="h-4"
                filled
              />
              <SvgoPaymentSystemMasterCard
                :font-controlled="false"
                class="h-4"
                filled
              />
              <SvgoPaymentSystemMir
                :font-controlled="false"
                class="h-4"
                filled
              />
            </div>
          </div>
        </div>
      </section>

      <section class="footer__copyright">
        {{ new Date().getFullYear() }} © {{ siteStore.contacts.copyrightText ?? 'Hellride.ru' }}
      </section>

      <section class="footer__legal-links">
        <template
          v-for="link in legalLinks"
          :key="link.id"
        >
          <AppLink
            v-if="link.enabled"
            :to="makeUrlRelative(link.value)"
          >
            {{ link.name }}
          </AppLink>
        </template>
      </section>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  &__wrapper {
    @include inner-wrapper;

    background-color: $hell-black-5;
  }

  &__content {
    justify-content: center;
    align-items: center;

    @include inner-content;

    & > * {
      width: 100%;
      padding: 20px 0;
      text-align: center;
    }

    @include screen-md {
      padding-bottom: unset;
    }
  }

  &__info {
    display: flex;
    gap: 24px;
    justify-content: center;
    border-top: 1px solid $hell-black-20;

    @include screen-md {
      justify-content: space-between;
    }
  }

  &__info-links {
    display: none;

    @include screen-md {
      display: grid;
      grid-template-columns: repeat(2, minmax(170px, 1fr));
      row-gap: 24px;
      justify-self: flex-start;
      font-size: $text-sm;
      column-gap: 16px;
    }

    @include screen-lg {
      grid-template-columns: repeat(4, minmax(170px, 1fr));
    }
  }

  &__contacts {
    display: flex;
  }

  &__social {
    display: flex;
    gap: 16px;
    justify-content: center;
  }

  &__payments {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }

  &__copyright {
    border-top: 1px solid $hell-black-20;
    color: $hell-black-60;
    font-size: 0.75rem;
  }

  &__legal-links {
    display: none;

    @include screen-md {
      display: flex;
      gap: 2rem;
      justify-content: center;
      color: $hell-black-60;
      font-weight: $medium;
      font-size: 0.825rem;
    }
  }
}
</style>
