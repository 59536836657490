<script lang="ts" setup>
import type { OfferFull, OfferMedium } from '~/types/Catalog';

defineProps<{ offer: OfferFull | OfferMedium }>();

const authStore = useAuthStore();
</script>

<template>
  <section
    v-if="authStore.accessRights?.can_view_balance"
    class="text-sm"
  >
    <p class="mb-1 italic text-hell-black-80">Наличие (для&nbsp;сотрудника)</p>

    <ul class="flex flex-col">
      <li
        v-for="warehouse in offer.offer_balances.filter((w) => w.warehouse.show).sort((a, b) => b.balance - a.balance)"
        :key="warehouse.warehouse.id"
      >
        <div class="flex items-start gap-1.5">
          <div
            :class="warehouse.balance ? 'bg-hell-green' : 'bg-hell-red'"
            class="rounded-full w-1.5 h-1.5 mt-[7px] flex-shrink-0"
          />

          <div class="leading-5">
            {{ warehouse.warehouse.show_name || warehouse.warehouse.name }}:
            <span class="font-bold">{{ warehouse.balance }}</span>
          </div>
        </div>
      </li>

      <li v-if="offer.popularity_rank">
        <div class="flex items-center gap-[3px] mt-2 -ml-[3px]">
          <SvgoStar
            class="text-xs"
            filled
          />

          <div class="leading-5">Ранг: {{ offer.popularity_rank }}</div>
        </div>
      </li>
    </ul>
  </section>
</template>
